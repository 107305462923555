'use client';

import { useTranslation } from 'react-i18next';
import { formatPhoneNumberSplit } from '@repo/common/utils/formatters';
import Link from 'next/link';
import { motion } from 'framer-motion';
import KeepQueryLink from './KeepQueryLink';
import HomeButton from './HomeButton';
import TrackedVideo from './TrackedVideo';

export default function WhatWeDoSection({ scrollTo = undefined }: { scrollTo?: string }) {
  const { t } = useTranslation();
  return (
    <section id="how-it-works" className="overflow-x-clip max-w-screen-xl mx-auto items-center flex flex-col md:flex-row px-8 py-12 gap-4">
      <motion.div
        className="grow basis-1/2 rounded-r-xl lg:px-8 py-4"
        initial={{ opacity: 0, translateX: -100 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ amount: 0.25, once: true }}
      >
        <h2 className="text-4xl mb-8 font-bold">
          {t('landing.what-we-do.title')}
        </h2>
        <div className="text-lg font-normal space-y-3">
          <ol>
            <li className="flex gap-4">
              <aside className="flex flex-col items-center">
                <span className="material-symbols-outlined text-blue-500 h-8 w-8 text-[22px] border-2 border-blue-500 rounded-full flex items-center justify-center">
                  account_balance
                </span>
                <div className="bg-black/20 w-px grow" />
              </aside>
              <div>
                <header className="font-semibold text-lg mb-2">
                  1.
                  {' '}
                  {t('landing.steps.1.title')}
                </header>
                <p className="text-base mb-6">
                  {t('landing.steps.1.description')}
                </p>
              </div>
            </li>
            <li className="flex gap-4">
              <aside className="flex flex-col items-center">
                <span className="material-symbols-outlined text-blue-500 h-8 w-8 text-[22px] border-2 border-blue-500 rounded-full flex items-center justify-center">
                  description
                </span>
                <div className="bg-black/20 w-px grow" />
              </aside>
              <div>
                <header className="font-semibold text-lg mb-2">
                  2.
                  {' '}
                  {t('landing.steps.2.title')}
                </header>
                <p className="text-base mb-6">
                  {t('landing.steps.2.description')}
                </p>
              </div>
            </li>
            <li className="flex gap-4">
              <aside className="flex flex-col items-center">
                <span className="material-symbols-outlined text-blue-500 h-8 w-8 text-[22px] border-2 border-blue-500 rounded-full flex items-center justify-center">
                  edit
                </span>
                <div className="bg-black/20 w-px grow" />
              </aside>
              <div>
                <header className="font-semibold text-lg mb-2">
                  3.
                  {' '}
                  {t('landing.steps.3.title')}
                </header>
                <p className="text-base mb-6">
                  {t('landing.steps.3.description')}
                </p>
              </div>
            </li>
            <li className="flex gap-4">
              <aside className="flex flex-col items-center">
                <span className="material-symbols-outlined text-blue-500 h-8 w-8 text-[22px] border-2 border-blue-500 rounded-full flex items-center justify-center">
                  attach_money
                </span>
              </aside>
              <div>
                <header className="font-semibold text-lg mb-2">
                  4.
                  {' '}
                  {t('landing.steps.4.title')}
                </header>
                <p className="text-base mb-6">
                  {t('landing.steps.4.description')}
                </p>
                <div className="flex flex-col lg:flex-row gap-2 lg:items-center">
                  <KeepQueryLink
                    href="/loan/intake/details"
                    query={{ utm_content: 'landing_page_steps' }}
                    onClick={(e) => {
                      console.log('scrollTo', scrollTo);
                      if (scrollTo) {
                        e.preventDefault();
                        const element = document.getElementById(scrollTo);
                        if (element) {
                          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                          const input = element.querySelector('input[type="text"]');
                          if (input && input instanceof HTMLInputElement) {
                            input.focus();
                          }
                        }
                      }
                    }}
                  >
                    <HomeButton size="lg" className="flex justify-center items-center gap-1.5">
                      <span className="material-symbols-outlined">
                        arrow_forward
                      </span>
                      {t('landing.steps.cta')}
                    </HomeButton>
                  </KeepQueryLink>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </motion.div>
      <motion.div
        className="grow basis-1/2 bg-blue-100/50 rounded-xl p-4 border border-blue-600/50"
        initial={{ opacity: 0, translateX: 100 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        transition={{ duration: 1 }}
        viewport={{ amount: 0.25, once: true }}
      >
        <h2 className="font-semibold mb-1">{t('landing.video.doubt')}</h2>
        <p className="mb-4">
          {t('landing.video.watch')}
        </p>
        <TrackedVideo name="ceo_video">
          <source src="/assets/ceo.mp4" type="video/mp4" />
        </TrackedVideo>
        <div>
          <p className="font-medium mb-2">{t('landing.video.contact')}</p>
          <div className="flex items-center gap-2 flex-wrap">
            <Link className="inline-block" href="mailto:support@otmow.com">
              <HomeButton variant="outline" size="lg" className="flex gap-1.5 items-center border-blue-600 rounded-full">
                <span className="material-symbols-outlined text-blue-500">
                  mail
                </span>
                {t('support@otmow.com')}
              </HomeButton>
            </Link>
            <Link className="inline-block" target="_blank" rel="noopener noreferrer" href={`https://wa.me/${process.env.NEXT_PUBLIC_WHATSAPP_CONTACT}`}>
              <HomeButton variant="outline" size="lg" className="flex gap-1.5 items-center border-green-600 rounded-full">
                <span className="material-symbols-outlined text-green-500">
                  contact_support
                </span>
                {formatPhoneNumberSplit(process.env.NEXT_PUBLIC_WHATSAPP_CONTACT!).phoneNumber}
              </HomeButton>
            </Link>
          </div>
        </div>
      </motion.div>
    </section>
  );
}
