'use client';

import { usePostHog } from 'posthog-js/react';
import React from 'react';

export default function TrackedVideo({ children = undefined, name }: React.PropsWithChildren<{ name: string }>) {
  const posthog = usePostHog();
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      className="w-full rounded-lg mb-4"
      controls
      poster="/assets/capa.jpg"
      onPlay={() => {
        posthog.capture('video_played', { name });
      }}
      onEnded={() => {
        posthog.capture('video_ended', { name });
      }}
    >
      {children}
    </video>
  );
}
